<template>
  <div class="page-container">
    <div class="page-header" v-if="state.moduleType == ModuleType.College">
         <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
            <van-tab :name="1" title="签约中"></van-tab>
            <van-tab :name="0" title="未签约"></van-tab>
        </van-tabs>
    </div>
    <div class="page-content">
        <div class="content-list">
            <div class="content-item" v-for="item in state.list" :key="item.id" @click="handleGoDetail(item)">
                <div class="item-header">
                    <van-image width="80" height="80" :src="getImg(item.faceImg)" fit="contain">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error>
                            <van-image width="80" height="80" :src="DefaultAvatar" />
                        </template>
                    </van-image>
                    <div style="margin-left: 12px;flex: 1;">
                        <div class="item-header-info">
                            <div class="item-header-info-name">{{item.realName}} <span>{{SexText[item.sex]}}</span></div>
                            <div class="item-header-info-status" v-if="active == 1">{{HospitalStatusText[item.status]}}</div>
                        </div>
                        <div class="item-header-info" v-if="active == 1">
                            <span class="item-header-info-other" style="color: #113050;font-size: 14px;font-weight: 500;">{{item.businessName}}</span>
                            <span class="item-header-info-other">{{ getDay(item.checkInDate,'YYYY-MM-DD') }}~{{ getDay(item.expireDate,'YYYY-MM-DD') }}</span>
                        </div>
                        <div v-if="active == 1" class="item-header-info"><span class="item-header-info-other">已签到{{item.record ?? '--'}}天，剩余<p>{{item.leftOver ?? '--'}}</p>天</span></div>
                    </div>
                </div>
                <div v-if="active == 1" class="item-des"><span></span> 最新回院：{{item.admissionTime ?? '--'}}</div>
                <div v-if="active == 1" class="item-des"><span  style="background:#5470C6"></span> 最新出院：{{item.exitTime ?? '--'}}</div>
                <div v-if="active == 1" class="item-des"><span  style="background:#383838"></span> 最新签到时间：{{getDay(item.recentPerformanceTime) ?? '--'}}</div>
                
            </div>
            <EmptyData style="margin-top: 100px" v-if="!state.list.length"/>
        </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from "vue-router";
  import { ModuleType, SexText, HospitalStatusText } from '@/utils/const' 
  import { queryGetSignStu } from "@/api/user";
  import EmptyData from '@/components/EmptyData.vue';
  import DefaultAvatar from '@/assets/avatar.jpg';
  import { getImg } from '@/utils/methods';
  import dayjs from "dayjs";

  export default defineComponent({
    name: 'CollegeStudents',
    
    components: {
        EmptyData
    },

    setup() {
     const route = useRoute();

     const router = useRouter();

     const active = ref(1)

     const state = reactive({
        list: [],
        moduleType : route?.query?.moduleType
     })

      onMounted(() => {
        queryGetSignStuData();
       
       
      });

    const queryGetSignStuData = async () => {
      try {
        const params = {
            signStatus: active.value
        }
        if(state.moduleType == ModuleType.College) {
            delete params.expireDate
        } else if(state.moduleType == ModuleType.Due) {
            // TODO  即将到期
            params.expireDate = dayjs().format('YYYY-MM-DD')
        }
        const result = await queryGetSignStu({
            page:{
                pageIndex: 1,
                pageSize: 100
            },
            ...params
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.list = result.data?.list ?? []
        } else {
          state.list = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [];
      }
    }

      const handleGoDetail = (row) => {
        router.push({
            path: '/studentsInfo',
            query: {
                userId: row.id
            }
        })
      }

      const handleClickTab = () => {
        queryGetSignStuData();
      };

      const getDay = (day,format='YYYY-MM-DD HH:mm:ss') => {
        return day ? dayjs(day).format(format) : '--'
      }

      return {
        active,
        state,
        ModuleType,
        handleGoDetail,
        DefaultAvatar,
        getImg,
        handleClickTab,
        SexText,
        HospitalStatusText,
        getDay
      };
    },
  });
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.page-header {
    padding: 12px;
    margin: 0 68px;
}

.content-item {
    background: #fff;
    margin: 12px 8px;
    border-radius: 4px;
    padding: 12px;
    text-align: left;

    .item-header {
        display: flex;

        .item-header-info {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;

            .item-header-info-name {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #000000;
                font-weight: 600;

                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: burlywood;
                }
            }

            .item-header-info-status {
                color: #fff;
                font-size: 14px;
                background: #113050;
                padding: 4px 12px;
                border-radius: 24px;
            }

            .item-header-info-other {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                display: flex;
                align-items: center;
                height: 28px;

                p {
                    color: #FF3638;
                }
            }

        }
    }

    .item-des {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;

        span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #EE6666;
            margin-right: 4px;
            display: inline-block;
        }
    }
}
</style>