<template>
  <div class="page-container">
    <div class="page-header">
         <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
            <van-tab :name="1" title="获得"></van-tab>
            <van-tab :name="2" title="支出"></van-tab>
        </van-tabs>
    </div>
    <div class="content-list" @scroll="handleScroll">
      <div  class="content-item" v-for="(item, index) in state.list" :key="index">
      <div class="item-header-info">
        <div class="item-header-info-name">
          <div>{{item.nameTitle || '--'}}</div>
          <div class="item-header-info-other">
            {{item.updateTime || '--'}}
          </div>
        </div>
        <div class="item-header-info-status-value" :style="{marginTop: active === 2 ? '8px' : '0px'}">
          {{item.operDesc || '--'}}
        </div>
        <div v-if="item.orderStatus && active === 2" :style="{
          background: item.orderStatus === 2 ? '#99D249' : '#FCAD28', color: item.orderStatus === 2 ? '#E9FFC9' : '#FFF2C4'
        }" class="content-item-tag">{{item.orderStatus === 2 ? '已核销' : '待核销'}}</div>
      </div>
    </div>

    <EmptyDataCard
        style="margin-top: 88px"
        v-show="!state.list.length"
      />
    </div>
    
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from "vue-router";
  import { ModuleType, SexText, HospitalStatusText } from '@/utils/const' 
  import { queryScoresGetDetailsPage } from "@/api/user";
  import DefaultAvatar from '@/assets/avatar.jpg';
  import { getImg } from '@/utils/methods';
  import dayjs from "dayjs";
  import EmptyDataCard from "@/components/EmptyDataCard.vue";

  export default defineComponent({
    name: 'PointsDetail',
    
    components: {
        EmptyDataCard
    },

    setup() {
     const route = useRoute();

     const router = useRouter();

     const active = ref(1)

     const state = reactive({
        list: [],
        page: {
          pageIndex: 1,
          pageSize: 10
        },
        isFinsh: false,
        loading: false,
        
     })

      onMounted(() => {
        if(route?.query?.token) {
          localStorage.setItem('token', route?.query?.token)
        }
        scoresGetDetailsPage();
      });

    const scoresGetDetailsPage = async () => {
      try {
        const params = {
            operType: active.value,
            userId: route?.query?.userId
        }
       
        const result = await queryScoresGetDetailsPage({
            page:{
                ...state.page
            },
            ...params
        });
        console.log("result:", result);
        if (result.code === "00") {
          const res = result.data.list.map(item => {
            return {
              ...item,
              nameTitle: `${item.costTypeStr || '--'} ${item.productName ? `: ${item.productName}` : ''}`
            }
          })
          state.list = [...state.list,...res]
          if(state.page.pageIndex >= result.data.pages){
            state.isFinsh = true
            return
          } else {
            state.loading = false;
            state.page.pageIndex ++;
          }
        } else {
          state.list = [...state.list];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [...state.list];
      }
    }

      const handleClickTab = () => {
        state.list = [];
        state.isFinsh = false;
        state.page.pageIndex = 1;
        scoresGetDetailsPage();
      };

      const getDay = (day) => {
        return dayjs(day).format('YYYY-MM-DD')
      }

      function handleScroll(event){
        console.log('event=====',event)
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const isBottom = scrollHeight - (scrollTop + clientHeight) <= 10; // 10是一个阈值，可以根据需要调整
        if (isBottom && !state.loading && !state.isFinsh) {
          scoresGetDetailsPage();
        }
      }

      return {
        active,
        state,
        ModuleType,
        DefaultAvatar,
        getImg,
        handleClickTab,
        SexText,
        HospitalStatusText,
        getDay,
        handleScroll
      };
    },
  });
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.page-header {
    padding: 12px;
    margin: 0 68px;
}

.content-list {
    height: calc(100vh - 66px);
    width: calc(100% - 12px);
    overflow-y: scroll;
    padding: 6px;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
  position: relative;

  &-tag {
      position: absolute;
      background: #99D249;
      height: 22px;
      line-height: 21px;
      width: 48px;
      border-radius: 0px 4px 0px 9px;
      right: 0px;
      top: 0px;
      font-family: Adobe Heiti Std;
      font-size: 11px;
      font-weight: 800;
      color: #E9FFC9;
      text-align: center;
  }
}

.item-header-info {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  align-items: center;

  .item-des {
    color: #333333;
    font-size: 14px;
    vertical-align: middle;
  }

  .item-header-info-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-weight: 600;

    span {
      font-size: 16px;
      font-weight: 500;
      color: burlywood;
    }
  }

  .item-header-info-status {
    color: #fff;
    font-size: 14px;
    background: #113050;
    padding: 4px 12px;
    border-radius: 24px;

    &-value {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }
    
  }

  &-text {
    color: #113050;
    font-size: 12px;
    padding: 4px 12px;
  }

  &-value {
    font-size: 12px;
    padding: 4px 12px;
  }

  

  .item-header-info-other {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    p {
      color: #ff3638;
    }
  }

  
}
</style>