<template>
  <div class="page-exchange">
    <div class="page-exchange-card">
      <div @click="handleSeeDetail" class="card-title">我的积分 ></div>
      <div class="card-value">{{state.totalScore}}</div>
    </div>

    <div class="page-exchange-list">
      <div class="list-title">兑换礼品</div>
      <div class="list-item-card" v-if="state.list.length">
        <div class="list-item" v-for="(item, index) in state.list" :key="index">
        <div class="item-card">
          <van-image
            width="156px"
            height="81px"
            :src="item.img"
          ></van-image>
        <div class="item-card-info">
           <div class="list-item-name">{{item.name}}</div>
           <div class="list-item-remaining" v-if="item.residual">剩余{{item.residual}}</div>
        </div>
        <div class="list-item-points">
          <van-image
            width="14px"
            height="14px"
            class="point-icon"
            :src="PointsIcon"
          ></van-image>
          {{item.scorePrice}}</div>
        </div>

        <div class="list-item-btn">
          <van-button :class="['van-btn-custom', item.isLimit && item.residual <= 0 || item.scorePrice > state.totalScore ? 'van-btn-disabled' : '']" size="small" @click="exchange(item)">
             {{ item.scorePrice > state.totalScore ? '积分不足' : item.isLimit && item.residual <= 0 ? '已抢光' : '立即兑换'}}</van-button>
        </div>
      </div>
      </div>
      <EmptyDataCard
        style="margin-top: 88px"
        v-show="!state.list.length"
      />
      
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import PointsIcon from '@/assets/poiints_icon.png'
import { useRouter, useRoute } from "vue-router";
import { querySelectUserById, queryProductList, queryProductExchange } from '@/api/user'
import { showConfirmDialog, showNotify } from 'vant';
import EmptyDataCard from "@/components/EmptyDataCard.vue";

export default defineComponent({
  name: "PointsExchange",

  components: {
    EmptyDataCard
  },

  setup() {

    const router = useRouter();

    const route = useRoute();

    const state = reactive({
      totalScore: 0,
      list: []
    });


    onMounted(() => {

      if(route?.query?.token) {
        localStorage.setItem('token', route?.query?.token)
      }

      getQuerySelectUserById()

      getQueryProductList()

    });

    const getQueryProductList = async () => {
      try {
        const result = await queryProductList({
            createOrg: route?.query?.creatOrg,
            page:{
                pageIndex: 1,
                pageSize: 40
            },
            isList: true
        });
        if (result.code === "00") {
         state.list = result.data?.list || []
        } 
      } catch (error) {
        console.log("error:>>>", error);
      }
    }


    const getQuerySelectUserById = async () => {
      try {
        const result = await querySelectUserById({
            id: route?.query?.userId
        });
        if (result.code === "00") {
         state.totalScore = result.data.score || 0
        } 
      } catch (error) {
        console.log("error:>>>", error);
      }
    }

    const handleSeeDetail = () => {
      router.push(`/points/detail?userId=${route?.query?.userId}&token=${route?.query?.token}`);
    }

    const exchange = (item) => {
      if(item.isLimit && (item.residual <= 0 || item.scorePrice > state.totalScore)) {
        console.log('========')
        return
      }

      showConfirmDialog({
        title: '温馨提示',
        message: '确定要兑换该商品吗？',
      })
        .then(async () => {
          const result = await queryProductExchange({
            id: item.id,
            userId: route?.query?.userId,
            scorePrice: item.scorePrice,
          })

          if (result.code === "00") {
              showNotify({ type: 'success', message: '商品兑换成功，请联系书院领取', duration: 2000 })
          } else {
              showNotify({ type: 'warning', message: result.message, duration: 2000 })
          }

          getQuerySelectUserById()
          getQueryProductList()
        })
        .catch(() => {
          // on cancel
        });

      

    }

    return {
      state,
      PointsIcon,
      handleSeeDetail,
      exchange
    };
  },
});
</script>

<style lang="scss" scoped>
.page-exchange {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;



  .page-exchange-card {
    width: calc(100% - 20px);
    height: 124px;
    background: #FFFFFF;
    border-radius: 7px;
    margin: 10px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .card-title {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .card-value {
      margin-top: 8px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 30px;
      color: #000000;
    }
  }

  .page-exchange-list {
    margin: 10px 10px 0;
    width: calc(100% - 20px);
    
    .list-title {
      height: 52px;
      line-height: 52px;
      font-family: PingFang SC;
      font-weight: 900;
      font-size: 17px;
      color: #000000;
      text-align: left;
    }

    .list-item-card{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .list-item {
        width: calc(50% - 5px);

        .item-card {
           background: #FFFFFF;
           border-radius: 7px;
           padding-bottom: 12px;
           padding-top: 24px;
        }

        .item-card-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 22px;
          align-items: center;

          .list-item-name {
            margin-left: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #000000;
            white-space: nowrap;
            width: 60%;
            text-align: left;
          }
          .list-item-remaining {
            margin-right: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #8E8E8E;
            white-space: nowrap;
            text-align: right;
          }
        }


        .list-item-points {
          margin-top: 4px;
          width: 100%;
          text-align: left;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #FFB839;
          display: flex;
          align-items: center;

          .point-icon {
            margin-left: 15px;
            margin-right: 4px;
          }
        }
       
      }

      .list-item-btn {
        margin: 18px 0;

        .van-btn-custom {
          background: #265883;
          width: 81px;
          height: 33px;
          color: white;
        }

        .van-btn-disabled {
          background: #A5A5A5;
          color: #FEFFFF;
        }
      }
    }
  }
}
</style>