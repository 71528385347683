<template>
  <div>
    <div>
        <van-image fit="contain" style="width: 160px; height: 160px; margin-top: 88px;" :src="require('@/assets/logo_daoshi.jpg')" />  
        <div style="margin-top: 44px;margin-bottom: 44px">
          登录              
        </div>
    </div>
    <van-form>
      
      <div style="margin: 44px 16px">
        <van-button style="margin-top: 16px" round block plain type="success" @click="loginByPhone">
          手机号快速登录
        </van-button>

        <van-button round block style="background:#013152;margin-top: 16px" type="primary" native-type="submit"  @click="loginByPassword">
          账号密码登录
        </van-button>

      </div>
    </van-form>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { loginSystem, queryLogInSendCode } from '@/api/user'
import { useRouter } from "vue-router";
import { showNotify } from 'vant';

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
        telephone: '',
        code: '',
        time: 60,
        isSendLoading: false,
    });

    const router = useRouter();

    onMounted(async () => {});

    const loginByPhone = () => {
      window.wx.miniProgram.navigateTo({delta: 1})
      window.wx.miniProgram.postMessage({
        data: {
          type: 'loginByPhone',
        }
      })
    }

    const loginByPassword = () => {
      router.push('/login');
    }

    return {
      state,
      loginByPhone,
      loginByPassword
    };
  },
});
</script>

<style scoped>
</style>
